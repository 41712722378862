import { Box, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';

const BackgroundGradient: React.FC<{
  minHeight?: string | number;
  children?: React.ReactElement;
  zIndex?: string;
}> = ({ minHeight = {}, children, zIndex }) => {
  const theme = useTheme();
  const [deviceHeight, setDeviceHeight] = useState<number>(0);

  useEffect(() => {
    setDeviceHeight(window.innerHeight);
  });

  useEffect(() => {
    const resizeH = () => setDeviceHeight(window.innerHeight);

    window.addEventListener('resize', resizeH);

    return () => window.removeEventListener('resize', resizeH);
  });

  return (
    <Box
      position="absolute"
      sx={{
        opacity: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '100%',
        zIndex: zIndex || 1,
        minHeight: minHeight ?? {
          xs: deviceHeight > 1080 ? '1080px' : '100vh',
          xl: '1080px'
        },
        height: '100%',
        borderRadius: 0,
        backgroundColor: 'transparent',
        backgroundPosition: 'absolute',
        background: `linear-gradient(180deg, ${theme.palette.background.paper} 0%, rgba(0, 0, 25, 0.24) 50%, ${theme.palette.background.paper} 100%)`
      }}
    >
      {children}
    </Box>
  );
};

export default BackgroundGradient;
