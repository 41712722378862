import { CloseIcon, LoginDuotoneIcon, MenuIcon } from '@bws-bitfy/icons-react';
import { Box, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import If from 'components/atoms/If';
import LanguageSelect from 'components/website/atoms/LanguageSelect';
import MenuButton from 'components/website/atoms/MenuButton';
import { IS_PROD_ENV } from 'constants/environments';
import { Routes } from 'constants/routes';
import Image from 'next/image';
import { useRouter } from 'next/router';

interface SmallMenuProps {
  selectedMenu?: string;
  handleOpenMenu?: () => void;
  openedMenu?: boolean;
}

const SmallMenu: React.FC<SmallMenuProps> = ({
  handleOpenMenu,
  openedMenu,
  selectedMenu
}) => {
  const router = useRouter();
  const theme = useTheme();

  return (
    <Box display="flex" width="100%" paddingX={{ xs: 1 }}>
      <Box display={{ xs: 'flex', lg: 'none' }}>
        <IconButton onClick={handleOpenMenu}>
          <If condition={openedMenu}>
            <CloseIcon
              fill={theme.palette.iconSecondary}
              width={25}
              height={25}
            />
          </If>
          <If condition={!openedMenu}>
            <MenuIcon
              fill={theme.palette.iconSecondary}
              width={25}
              height={25}
            />
          </If>
        </IconButton>
      </Box>
      <Box
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          ml={{ xs: 1.25, sm: 3 }}
          minWidth="80px"
          onClick={() => {
            window.scrollTo(0, 0);
            router.push({
              pathname: Routes.LANDING_PAGE
            });
          }}
          sx={{
            cursor: 'pointer'
          }}
        >
          <Image width={55} height={35} src="/baas-logo.svg" priority />
        </Box>
        <If condition={!IS_PROD_ENV}>
          <Box display="flex" alignItems="center" gap={2}>
            <If condition={selectedMenu !== 'sign-in'}>
              <MenuButton
                selectedMenu={selectedMenu}
                icon={<LoginDuotoneIcon width={18} />}
                route={Routes.SIGN_IN}
                name="sign-in"
                mixpanelName="navbar_login"
                labelIntlPath="webSiteEn.Menu.option4"
              />
            </If>

            <Box marginLeft={0}>
              <LanguageSelect hideLabel selectionBoxPosition="bottom" />
            </Box>
          </Box>
        </If>
      </Box>
    </Box>
  );
};

export default SmallMenu;
