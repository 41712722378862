import { Box, Grid, useTheme } from '@mui/material';
import If from 'components/atoms/If';
import BackgroundGradient from 'components/website/atoms/backgroundGradiant';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';

interface SectionBackgroundProps {
  url?: string;
  children: React.ReactNode;
  limitedHeight?: boolean;
  minHeight?: string | number;
  fullScreen?: boolean;
  backgroundOpacity?: number;
  noPadding?: boolean;
  disableGradient?: boolean;
  fullHeight?: boolean;
}

const SectionBackground: React.FC<SectionBackgroundProps> = ({
  url,
  children,
  limitedHeight,
  minHeight,
  fullScreen,
  backgroundOpacity,
  noPadding,
  disableGradient,
  fullHeight
}) => {
  const theme = useTheme();
  const [deviceHeight, setDeviceHeight] = useState<number>(0);

  useEffect(() => {
    setDeviceHeight(window.innerHeight);
  });

  useEffect(() => {
    const resizeH = () => setDeviceHeight(window.innerHeight);

    window.addEventListener('resize', resizeH);

    return () => {
      window.removeEventListener('resize', resizeH);
    };
  });

  return (
    <Box
      position="relative"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper
      }}
    >
      <If condition={!limitedHeight && !disableGradient}>
        <BackgroundGradient minHeight={minHeight} />
      </If>

      <If condition={url?.length > 0 && !limitedHeight}>
        <Box
          width="100%"
          sx={{
            opacity: backgroundOpacity || 1
          }}
        >
          <Image src={url} layout="fill" objectFit="cover" priority />
        </Box>
      </If>

      <If condition={url?.length > 0 && limitedHeight}>
        <Box
          sx={{
            opacity: backgroundOpacity || 1
          }}
        >
          <Grid
            container
            item
            height="100%"
            position="absolute"
            zIndex={1}
            top={0}
            left={0}
            right={0}
            bottom={0}
            maxHeight={{ xs: 584, md: 800 }}
          >
            <BackgroundGradient />

            <Box position="relative" width="100%">
              <Image
                src={`${url}.jpg`}
                layout="fill"
                objectPosition="center"
                objectFit="cover"
                priority
              />
            </Box>
          </Grid>
        </Box>
      </If>

      <If condition={fullScreen}>
        <Box
          justifyContent="center"
          minHeight={
            minHeight ?? {
              xs:
                deviceHeight > 1080
                  ? `${fullHeight ? 'calc(100vh - 273px - 112px)' : '1080px'}`
                  : 'calc(100vh - 112px)'
            }
          }
          width="100%"
          maxWidth={{
            xs: '100%',
            sm: 592,
            md: 1056,
            lg: 1056,
            xl: 1264
          }}
          paddingX={{ xs: 2, md: 4, lg: 0 }}
          display="flex"
          zIndex={10}
        >
          {children}
        </Box>
      </If>

      <If condition={!fullScreen}>
        <Box
          justifyContent="center"
          width="100%"
          maxWidth={{
            xs: '100%',
            sm: 592,
            md: 1056,
            lg: 1056,
            xl: 1264
          }}
          paddingX={{ xs: 2, md: 4, lg: 0 }}
          paddingY={{
            xs: noPadding ? 0 : 6,
            md: noPadding ? 0 : '40px',
            lg: noPadding ? 0 : '80px'
          }}
          display="flex"
          zIndex={10}
        >
          {children}
        </Box>
      </If>
    </Box>
  );
};

export default SectionBackground;
