import { Link } from '@mui/material';
import Condition from 'components/atoms/Condition';
import Text from 'components/atoms/Text';
import { TranslationValuePath } from 'i18n';
import mixpanel from 'mixpanel-browser';
import { useRouter } from 'next/router';

interface FooterPagesLinkProps {
  text?: string;
  intlPath?: TranslationValuePath;
  url: string;
  isExternalURL?: boolean;
}

const FooterPagesLink: React.FC<FooterPagesLinkProps> = ({
  text,
  url,
  isExternalURL,
  intlPath
}) => {
  const router = useRouter();

  return (
    <Condition>
      <Condition.If condition={isExternalURL}>
        <Link
          underline="none"
          href={url}
          onClick={() =>
            mixpanel.track(`footer_${text.toLowerCase().replace(/\s/g, '')}`)
          }
          target="_blank"
        >
          <Text
            variant="h6"
            color="text.secondary"
            textAlign="left"
            sx={{
              whiteSpace: 'nowrap',
              ':hover': {
                cursor: 'pointer'
              }
            }}
            intlPath={intlPath}
          >
            {text}
          </Text>
        </Link>
      </Condition.If>
      <Condition.Else>
        <Text
          variant="h6"
          color="text.secondary"
          sx={{
            whiteSpace: 'nowrap',
            ':hover': {
              cursor: 'pointer'
            }
          }}
          onClick={() => {
            mixpanel.track(`footer_${text.toLowerCase().replace(/\s/g, '')}`);
            router.push({ pathname: url });
          }}
          intlPath={intlPath}
        >
          {text}
        </Text>
      </Condition.Else>
    </Condition>
  );
};

export default FooterPagesLink;
