import { DropdownIcon } from '@bws-bitfy/icons-react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import If from 'components/atoms/If';
import Text from 'components/atoms/Text';
import { Language, LanguageContext } from 'contexts/LanguageContext';
import i18n from 'i18n';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useCallback, useContext, useState } from 'react';

interface LanguageSelectProps {
  hideLabel?: boolean;
  selectionBoxPosition?: 'top' | 'bottom';
}

const LanguageSelect: React.FC<LanguageSelectProps> = ({
  hideLabel,
  selectionBoxPosition = 'top'
}) => {
  const theme = useTheme();
  const router = useRouter();

  const [openLanguageSelection, setOpenLanguageSelection] = useState(false);

  const { languages } = useContext(LanguageContext);

  const handleLanguageSelection = useCallback((language: Language) => {
    setOpenLanguageSelection(false);
    i18n.changeLanguage(language.value);
    router.push(router.pathname, router.asPath, { locale: language.value });
  }, []);

  return (
    <Box display="flex" flexDirection="column" gap={hideLabel ? 0 : 3}>
      <If condition={!hideLabel}>
        <Text
          color="text.primary"
          fontWeight={500}
          intlPath="settings_page.settings_menu_items.items.languages"
        />
      </If>

      <Box
        display="flex"
        position="relative"
        alignItems="center"
        gap={1}
        sx={{
          ':hover': {
            cursor: 'pointer'
          }
        }}
        onClick={() => {
          setOpenLanguageSelection(!openLanguageSelection);
        }}
      >
        <Image
          style={{ borderRadius: '4px' }}
          objectFit="cover"
          src={
            languages?.find((item) => i18n.language.includes(item.value))
              ?.iconSrc
          }
          width={24}
          height={24}
        />
        <DropdownIcon
          width={18}
          height={18}
          fill={theme.palette.text.secondary}
        />
        <Box
          display="flex"
          position="absolute"
          bottom={selectionBoxPosition === 'top' ? 50 : -120}
          left={-20}
          bgcolor="background.elevated"
          paddingY={2}
          paddingX={2}
          gap={3}
          borderRadius="12px"
          boxSizing="content-box"
          flexDirection="column"
          visibility={openLanguageSelection ? 'visible' : 'hidden'}
        >
          {languages.map((language, index) => (
            <Box
              key={index}
              width={24}
              height={24}
              sx={{
                ':hover': {
                  cursor: 'pointer'
                }
              }}
              onClick={() => handleLanguageSelection(language)}
            >
              <Image
                style={{ borderRadius: '4px' }}
                objectFit="cover"
                width={24}
                height={24}
                src={language?.iconSrc}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default LanguageSelect;
