import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  LoginDuotoneIcon,
  TwitterIcon,
  YoutubeIcon
} from '@bws-bitfy/icons-react';
import { Box, Grid, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import IconButton from 'components/atoms/IconButton/IconButton';
import If from 'components/atoms/If';
import Text from 'components/atoms/Text';
import LanguageSelect from 'components/website/atoms/LanguageSelect';
import FooterPagesLink from 'components/website/atoms/footerPagesLink';
import { IS_PROD_ENV, IS_SANDBOX_ENV } from 'constants/environments';
import { Routes } from 'constants/routes';
import { socialMediaURL } from 'constants/socialMediaURL';
import mixpanel from 'mixpanel-browser';
import Image from 'next/image';

const Footer: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      bgcolor="background.default"
      paddingY={8}
      component="footer"
      display="flex"
      justifyContent="center"
    >
      <Grid
        container
        justifyContent="space-between"
        paddingX={{ xs: 2, md: 4, lg: 0 }}
        width={{
          xs: '100%',
          sm: 592,
          md: 1056,
          lg: 1056,
          xl: 1264
        }}
      >
        <Grid container wrap="nowrap" item xs={12} maxWidth={1264}>
          <Grid
            container
            item
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item>
              <Grid container alignItems="center" spacing={6}>
                <Grid item sm={6} md="auto" marginRight={2}>
                  <Image width={64} height={64} src="/baas-logo.svg" />
                </Grid>

                <Grid item sm={6} md="auto">
                  <FooterPagesLink
                    intlPath="webSiteEn.Menu.option1"
                    text="Solutions"
                    url={Routes.WS_SERVICES}
                  />
                </Grid>
                <If condition={!IS_SANDBOX_ENV}>
                  <Grid item sm={6} md="auto">
                    <FooterPagesLink
                      intlPath="webSiteEn.Menu.option2"
                      text="Pricing"
                      url={Routes.PRICING}
                    />
                  </Grid>
                </If>
                <Grid item sm={6} md="auto">
                  <FooterPagesLink
                    intlPath="webSiteEn.Menu.option3"
                    text="Get Started"
                    url={Routes.GET_STARTED}
                  />
                </Grid>

                <Grid item sm={6} md="auto">
                  <FooterPagesLink
                    isExternalURL
                    text="Documentation"
                    intlPath="webSiteEn.Menu.option5"
                    url={Routes.DOCUMENTATION}
                  />
                </Grid>
              </Grid>
            </Grid>
            <If condition={!IS_PROD_ENV}>
              <Grid item>
                <Box display="flex" gap={1} alignItems="center">
                  <LoginDuotoneIcon fill={theme.palette.text.primary} />
                  <FooterPagesLink
                    intlPath="webSiteEn.Menu.option4"
                    text="Login"
                    url={Routes.SIGN_IN}
                  />
                </Box>
              </Grid>
            </If>
          </Grid>
        </Grid>

        <Grid
          marginTop={{ xs: 4, sm: 8 }}
          container
          item
          xs={12}
          maxWidth={1264}
          spacing={4}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item order={{ xs: 2, sm: 1 }} xs={12} sm={6} md="auto">
            <Box display="flex" flexDirection="column" gap={2}>
              <Text
                fontWeight={500}
                variant="h6"
                color="text.primary"
                intlPath="webSiteEn.Footer.social_media_text"
              />
              <Box display="flex" gap={1.5}>
                <IconButton
                  onClick={() => {
                    mixpanel.track('footer_instagram');
                    window.open(socialMediaURL.INSTAGRAM);
                  }}
                  sx={{
                    backgroundColor: theme.palette.divider,
                    borderRadius: '12px',
                    ':hover': {
                      backgroundColor: theme.palette.divider
                    }
                  }}
                >
                  <InstagramIcon fill={theme.palette.iconSecondary} />
                </IconButton>
                <IconButton
                  sx={{
                    backgroundColor: theme.palette.divider,
                    borderRadius: '12px',
                    ':hover': {
                      backgroundColor: theme.palette.divider
                    }
                  }}
                  onClick={() => {
                    mixpanel.track('footer_facebook');
                    window.open(socialMediaURL.FACEBOOK);
                  }}
                >
                  <FacebookIcon fill={theme.palette.iconSecondary} />
                </IconButton>
                <IconButton
                  sx={{
                    backgroundColor: theme.palette.divider,
                    borderRadius: '12px',
                    ':hover': {
                      backgroundColor: theme.palette.divider
                    }
                  }}
                  onClick={() => {
                    mixpanel.track('footer_youtube');
                    window.open(socialMediaURL.YOUTUBE);
                  }}
                >
                  <YoutubeIcon fill={theme.palette.iconSecondary} />
                </IconButton>
                <IconButton
                  sx={{
                    backgroundColor: theme.palette.divider,
                    borderRadius: '12px',
                    ':hover': {
                      backgroundColor: theme.palette.divider
                    }
                  }}
                  onClick={() => {
                    mixpanel.track('footer_linkedin');
                    window.open(socialMediaURL.LINKEDIN);
                  }}
                >
                  <LinkedinIcon fill={theme.palette.iconSecondary} />
                </IconButton>
                <IconButton
                  sx={{
                    backgroundColor: theme.palette.divider,
                    borderRadius: '12px',
                    ':hover': {
                      backgroundColor: theme.palette.divider
                    }
                  }}
                  onClick={() => {
                    mixpanel.track('footer_twitter');
                    window.open(socialMediaURL.TWITTER);
                  }}
                >
                  <TwitterIcon fill={theme.palette.iconSecondary} />
                </IconButton>
              </Box>
            </Box>
          </Grid>

          <Grid item order={{ xs: 3, md: 2 }} xs={12} sm="auto">
            <Text
              fontWeight={500}
              color="text.secondary"
              textAlign="center"
              intlPath="webSiteEn.Footer.description1"
              interpolate={{
                year: new Date().getFullYear()
              }}
              variant="body2"
            />
            <Text
              textAlign="center"
              fontWeight={500}
              color="text.secondary"
              intlPath="webSiteEn.Footer.description2"
              variant="body2"
              marginBottom={2}
            />

            <Link
              textAlign="center"
              underline="none"
              href={process.env.NEXT_PUBLIC_SERVICES_STATUS_URL}
              onClick={() => mixpanel.track(`footer_services_status`)}
              target="_blank"
            >
              <Text
                fontWeight={500}
                color="text.secondary"
                sx={{ textDecoration: 'underline' }}
                intlPath="webSiteEn.Footer.services_status"
              />
            </Link>
          </Grid>

          <Grid
            item
            container
            order={{ xs: 1, sm: 2, md: 3 }}
            justifyContent={{ xs: 'start', sm: 'end' }}
            xs={12}
            sm={6}
            md="auto"
          >
            <LanguageSelect />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
