/* eslint-disable no-nested-ternary */
import { Box, Link, useTheme } from '@mui/material';
import Button from 'components/atoms/Button';
import Condition from 'components/atoms/Condition';
import { TranslationValuePath } from 'i18n';
import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';

interface MenuButtonProps {
  selectedMenu: string;
  name: string;
  route?: string;
  label?: string;
  labelIntlPath?: TranslationValuePath;
  icon?: ReactElement;
  externalRoute?: string;
  mixpanelName?: string;
}

const MenuButton: React.FC<MenuButtonProps> = ({
  selectedMenu,
  name,
  route,
  label,
  labelIntlPath,
  icon,
  externalRoute,
  mixpanelName
}) => {
  const theme = useTheme();
  const router = useRouter();
  const isException = selectedMenu === 'forgot-password' && name === 'sign-in';

  const selectedButtonTextStyle = {
    fontWeight: icon ? 700 : 500,
    color: 'inherit',
    fontSize: { xs: name === 'sign-in' ? '14px' : '20px', lg: '20px' },
    whiteSpace: 'nowrap',
    svg: {
      fill: theme.palette.primaryOverlays[200]
    }
  };

  const notSelectedButtonTextStyle = {
    fontWeight: icon ? 700 : 500,
    color: 'inherit',
    ':hover': {
      color: 'text.primary',
      svg: {
        fill: theme.palette.primaryOverlays[200]
      }
    },
    fontSize: { xs: name === 'sign-in' ? '14px' : '20px', lg: '20px' },
    maxWidth: { xs: name === 'sign-in' ? '89px' : 'auto', lg: 'auto' },
    whiteSpace: 'nowrap',
    svg: {
      fill: theme.palette.text.secondary
    }
  };
  return (
    <Condition>
      <Condition.If condition={!!externalRoute}>
        <Link underline="none" href={externalRoute} target="_blank">
          <Button
            data-mixpanel={mixpanelName}
            startIcon={
              icon ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                  {icon}
                </Box>
              ) : null
            }
            size="large"
            sx={{
              height: { xs: name === 'sign-in' ? '36px' : '48px', lg: '48px' },
              color: 'text.secondary',
              ':hover': {
                color: 'text.primary'
              },
              backgroundColor:
                selectedMenu === name || isException
                  ? theme.palette.divider
                  : 'transparent'
            }}
            intlpathprops={{
              sx:
                selectedMenu === name || isException
                  ? selectedButtonTextStyle
                  : notSelectedButtonTextStyle
            }}
          >
            {label}
          </Button>
        </Link>
      </Condition.If>
      <Condition.Else>
        <Button
          data-mixpanel={mixpanelName}
          startIcon={icon}
          intlpathprops={{
            sx:
              selectedMenu === name || isException
                ? selectedButtonTextStyle
                : notSelectedButtonTextStyle
          }}
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: { xs: name === 'sign-in' ? '36px' : '48px', lg: '48px' },
            color:
              selectedMenu === name || isException
                ? theme.palette.primaryOverlays[900]
                : 'text.secondary',
            ':hover': {
              color: 'text.primary'
            },
            backgroundColor:
              selectedMenu === name || isException
                ? theme.palette.primary.main
                : 'transparent'
          }}
          size="large"
          onClick={() => {
            router.push({
              pathname: route
            });
          }}
          intlpath={labelIntlPath}
        >
          {label}
        </Button>
      </Condition.Else>
    </Condition>
  );
};

export default MenuButton;
