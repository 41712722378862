import { LoginDuotoneIcon, MenuIcon } from '@bws-bitfy/icons-react';
import { Box, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import If from 'components/atoms/If';
import LanguageSelect from 'components/website/atoms/LanguageSelect';
import MenuButton from 'components/website/atoms/MenuButton';
import { IS_PROD_ENV, IS_SANDBOX_ENV } from 'constants/environments';
import { Routes } from 'constants/routes';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

interface BigMenuProps {
  selectedMenu: string;
}

const BigMenu: React.FC<BigMenuProps> = ({ selectedMenu }) => {
  const router = useRouter();
  const theme = useTheme();
  const { t } = useTranslation('webSiteEn');

  return (
    <Box
      display="flex"
      justifyContent="center"
      maxWidth={1264}
      width={{
        xs: '100%',
        sm: 592,
        md: 1056,
        lg: 1056,
        xl: 1264
      }}
    >
      <Box display={{ xs: 'flex', md: 'none' }}>
        <IconButton>
          <MenuIcon fill={theme.palette.iconSecondary} />
        </IconButton>
      </Box>
      <Box display={{ xs: 'none', md: 'flex' }} width="100%">
        <Box display={{ xs: 'none', md: 'flex' }} width="100%">
          <Box
            mr={4}
            minWidth="80px"
            onClick={() => {
              window.scrollTo(0, 0);
              router.push({
                pathname: Routes.LANDING_PAGE
              });
            }}
            sx={{
              cursor: 'pointer'
            }}
          >
            <Image priority width="61px" height="61px" src="/baas-logo.svg" />
          </Box>

          <Box display="flex" alignItems="center" pr="16px">
            <MenuButton
              selectedMenu={selectedMenu}
              name="services"
              route={Routes.WS_SERVICES}
              label={t('webSiteEn.Menu.option1')}
              mixpanelName="navbar_services"
            />
          </Box>

          <If condition={!IS_SANDBOX_ENV}>
            <Box display="flex" alignItems="center" pr="16px">
              <MenuButton
                selectedMenu={selectedMenu}
                name="pricing"
                route={Routes.PRICING}
                label={t('webSiteEn.Menu.option2')}
                mixpanelName="navbar_pricing"
              />
            </Box>
          </If>

          <Box display="flex" alignItems="center" pr="16px">
            <MenuButton
              selectedMenu={selectedMenu}
              name="get-started"
              route={Routes.GET_STARTED}
              label={t('webSiteEn.Menu.option3')}
              mixpanelName="navbar_getstarted"
            />
          </Box>

          <Box display="flex" alignItems="center">
            <MenuButton
              selectedMenu={selectedMenu}
              name="documentation"
              externalRoute={Routes.DOCUMENTATION}
              label={t('webSiteEn.Menu.option5')}
              mixpanelName="navbar_documentation"
            />
          </Box>
        </Box>

        <If condition={!IS_PROD_ENV}>
          <Box display="flex" alignItems="center" gap={2}>
            <MenuButton
              selectedMenu={selectedMenu}
              name="sign-in"
              route={Routes.SIGN_IN}
              label={t('webSiteEn.Menu.option4')}
              mixpanelName="navbar_login"
              icon={
                <Box display="flex" alignItems="center" width={24} height={24}>
                  <LoginDuotoneIcon />
                </Box>
              }
            />
          </Box>
        </If>
        <Box marginLeft={2} display="flex" alignItems="center">
          <LanguageSelect hideLabel selectionBoxPosition="bottom" />
        </Box>
      </Box>
    </Box>
  );
};

export default BigMenu;
