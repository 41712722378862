import { Backdrop, Box, Collapse, Grid, Slide } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import EnvironmentAlert from 'components/atoms/EnvironmentAlert';
import If from 'components/atoms/If';
import MenuButton from 'components/website/atoms/MenuButton';
import BigMenu from 'components/website/molecules/BigMenu';
import SmallMenu from 'components/website/molecules/SmallMenu';
import { IS_SANDBOX_ENV } from 'constants/environments';
import { Routes } from 'constants/routes';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

interface MenuProps {
  handleOpenMenu?: () => void;
  openedMenu: boolean;
  scrollDir: string;
}

const Menu: React.FC<MenuProps> = ({
  handleOpenMenu,
  openedMenu,
  scrollDir
}) => {
  const router = useRouter();
  const theme = useTheme();
  const selectedMenu = router.pathname.replace('/', '');

  const { t } = useTranslation('webSiteEn');

  return (
    <Slide
      appear={false}
      direction="down"
      in={scrollDir === 'scrolling up'}
      mountOnEnter
      unmountOnExit
    >
      <Grid
        container
        flexDirection="row"
        justifyContent="center"
        sx={{
          position: 'fixed',
          width: 'calc(100% - 7.5px)',
          backgroundColor: openedMenu
            ? theme.palette.background.default
            : theme.palette.background.paper,
          zIndex: 11
        }}
      >
        <If condition={IS_SANDBOX_ENV}>
          <EnvironmentAlert />
        </If>
        <Grid
          item
          display="flex"
          boxSizing="content-box"
          alignItems="center"
          height={{ xs: '56px', lg: '112px', zIndex: 7 }}
          width={{
            xs: '100%',
            sm: 592,
            md: 1056,
            lg: 1056,
            xl: 1264
          }}
        >
          <Box
            display={{ xs: 'none', lg: 'flex' }}
            width="100%"
            justifyContent="space-between"
          >
            <BigMenu selectedMenu={selectedMenu} />
          </Box>
          <Box
            display={{ xs: 'flex', lg: 'none' }}
            width="100%"
            justifyContent="space-between"
          >
            <SmallMenu
              selectedMenu={selectedMenu}
              handleOpenMenu={handleOpenMenu}
              openedMenu={openedMenu}
            />
          </Box>
        </Grid>
        <Collapse
          sx={{
            display: openedMenu ? 'inherit' : 'none'
          }}
          orientation="horizontal"
          in={openedMenu}
          collapsedSize={50}
        >
          <If condition={openedMenu}>
            <Box display={{ xs: 'initial', lg: 'none' }}>
              <Backdrop
                open
                onClick={handleOpenMenu}
                sx={{
                  width: '100%',
                  alignItems: 'flex-start',
                  marginTop: IS_SANDBOX_ENV ? '100px' : '56px',
                  zIndex: 30000000
                }}
              >
                <Box
                  sx={{
                    backgroundColor: theme.palette.background.default,
                    minHeight: '100px',
                    width: '100%',
                    display: 'grid',
                    gap: 4,
                    flexDirection: 'column',
                    paddingLeft: '16px',
                    paddingBottom: '32px',
                    pt: '32px'
                  }}
                >
                  <Box>
                    <MenuButton
                      selectedMenu={selectedMenu}
                      name="services"
                      route={Routes.WS_SERVICES}
                      label={t('webSiteEn.Menu.option1')}
                      mixpanelName="navbar_services"
                    />
                  </Box>
                  <If condition={!IS_SANDBOX_ENV}>
                    <Box>
                      <MenuButton
                        selectedMenu={selectedMenu}
                        name="pricing"
                        route={Routes.PRICING}
                        label={t('webSiteEn.Menu.option2')}
                        mixpanelName="navbar_pricing"
                      />
                    </Box>
                  </If>
                  <Box>
                    <MenuButton
                      selectedMenu={selectedMenu}
                      name="get-started"
                      route={Routes.GET_STARTED}
                      label={t('webSiteEn.Menu.option3')}
                      mixpanelName="navbar_getstarted"
                    />
                  </Box>

                  <Box>
                    <MenuButton
                      selectedMenu={selectedMenu}
                      name="documentation"
                      externalRoute={Routes.DOCUMENTATION}
                      label={t('webSiteEn.Menu.option5')}
                      mixpanelName="navbar_documentation"
                    />
                  </Box>
                </Box>
              </Backdrop>
            </Box>
          </If>
        </Collapse>
      </Grid>
    </Slide>
  );
};

export default Menu;
