import { ISignInForm } from 'components/molecules/SignInForm/SignInForm';
import { ISignUpForm } from 'components/molecules/SignUpForm/SignUpForm';
import { Routes } from 'constants/routes';

import { HookCallbackFunctions } from 'hooks/types';
import { signIn } from 'next-auth/react';
import { SnackbarMessage, useSnackbar } from 'notistack';
import { useState } from 'react';
import { BaaSServerResponse } from 'services/api/APIService';
import { SignupInviteMemberBodyData } from 'services/api/schemas/InviteMemberSchema';
import {
  resendConfirmationCode,
  signUp,
  verifyRegisterValidationCode
} from 'services/authServices';
import { signupInviteMember } from 'services/memberServices';

interface ISignInHookPOSTBody extends ISignInForm {
  twoFACode?: string;
}

export interface IAuthHook {
  reCaptchaToken?: string;
  handleSignIn?: (
    values: ISignInHookPOSTBody,
    callbacks?: HookCallbackFunctions<BaaSServerResponse>
  ) => void;
  handleReCaptchaToken?: (token: string) => void;
  handleConfirmCode?: (
    code: string,
    callbacks?: HookCallbackFunctions<BaaSServerResponse>
  ) => void;
  handleSignUp?: (
    user: ISignUpForm,
    callbacks?: HookCallbackFunctions<BaaSServerResponse>
  ) => void;
  handleResendConfirmationCode: () => void;
  email?: string;
  loading?: boolean;
  password?: string;
  setEmail?: (email: string) => void;
  handleSignUpInviteMember: (
    body: SignupInviteMemberBodyData,
    callbacks?: HookCallbackFunctions<BaaSServerResponse>
  ) => void;
}

export const SIGN_IN_ERRORS = {
  EMAIL_NOT_VERIFIED: 'E-mail not verified.',
  INVALID_SIGN_IN: 'Sign-in invalid.',
  RECAPTCHA_TOKEN_INVALID: 'Recaptcha token invalid.',
  TWO_FACTOR_LOGIN: '2FA login.',
  TWO_FACTOR_INVALID_CODE: '2FA code invalid.',
  TWO_FACTOR_REGISTER: '2FA register.',
  GOOGLE_NOT_VERIFIED: 'User without register with OAuth.'
};

function useAuth(): IAuthHook {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [reCaptchaToken, setReCaptchaToken] = useState<string>('');

  const { enqueueSnackbar } = useSnackbar();

  const handleReCaptchaToken = (token: string) => {
    setReCaptchaToken(token);
  };

  const handleSignIn = async (
    values: ISignInHookPOSTBody,
    callbacks?: HookCallbackFunctions
  ) => {
    const { onSuccess, onError } = callbacks ?? {};

    setLoading(true);
    setEmail(values.email);
    setPassword(values.password);

    await signIn('dashboard-signin', {
      callbackUrl: Routes.DASHBOARD,
      redirect: false,
      email: values.email,
      password: values.password,
      twoFACode: values.twoFACode,
      reCaptchaToken
    }).then((res) => {
      if (res.ok) {
        onSuccess?.({
          success: true,
          message: 'login success',
          message_type: 'Success'
        });
      }

      if (res.error) {
        onError?.({
          success: false,
          message: res.error,
          message_type: 'Error'
        });
      }
    });
    setLoading(false);
  };

  const handleResendConfirmationCode = async () => {
    setLoading(true);
    await resendConfirmationCode(email).then((response) => {
      enqueueSnackbar({
        type: response?.success ? 'success' : 'error',
        titleIntlPath: response?.success
          ? 'snackbar_messages.success_message.resend_confirmation_code'
          : 'snackbar_messages.success_message.resend_confirmation_code_failed'
      } as unknown as SnackbarMessage);
    });
    setLoading(false);
  };

  const handleConfirmCode = async (
    code: string,
    callbacks?: HookCallbackFunctions
  ) => {
    const { onSuccess, onError } = callbacks ?? {};
    setLoading(true);

    await verifyRegisterValidationCode(email, code).then((response) => {
      if (response?.success) {
        onSuccess?.(response);
        return;
      }
      onError?.(response);
    });
    setLoading(false);
  };

  const handleSignUp = async (
    values: ISignUpForm,
    callbacks?: HookCallbackFunctions
  ) => {
    const { onSuccess, onError } = callbacks ?? {};
    setLoading(true);
    setEmail(values.email);
    setPassword(values.password);

    await signUp(values, reCaptchaToken).then(async (response) => {
      if (response?.success) {
        onSuccess?.(response);
        return;
      }
      onError?.(response);
    });
    setLoading(false);
  };

  const handleSignUpInviteMember = async (
    values: SignupInviteMemberBodyData,
    callbacks?: HookCallbackFunctions
  ) => {
    const { onSuccess, onError } = callbacks ?? {};
    setLoading(true);
    await signupInviteMember(
      { body: values }
      // reCaptchaToken,
    ).then(async (response) => {
      if (response?.success) {
        onSuccess?.(response);
        return;
      }
      onError?.(response);
    });
    setLoading(false);
  };

  return {
    handleSignIn,
    handleReCaptchaToken,
    handleConfirmCode,
    handleSignUp,
    handleResendConfirmationCode,
    loading,
    reCaptchaToken,
    email,
    password,
    setEmail,
    handleSignUpInviteMember
  };
}

export default useAuth;
