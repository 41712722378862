import { Box, LinearProgress } from '@mui/material';
import Condition from 'components/atoms/Condition';
import If from 'components/atoms/If';
import CookieWarningBanner from 'components/website/molecules/CookieWarning';
import Menu from 'components/website/molecules/Menu';
import Footer from 'components/website/organisms/Footer';
import { useEffect, useState } from 'react';

interface WebsiteTemplateProps {
  children: React.ReactNode;
  loading?: boolean;
}

const WebsiteTemplate: React.FC<WebsiteTemplateProps> = ({
  children,
  loading
}) => {
  const [openedMenu, setOpenedMenu] = useState<boolean>(false);
  const [scrollDir, setScrollDir] = useState('scrolling up');
  const [showCookieWarning, setShowCookieWarning] = useState(true);

  useEffect(() => {
    const hasCookieConsent = sessionStorage.getItem('cookieConsent');

    if (hasCookieConsent) {
      setShowCookieWarning(false);
    }
  }, []);

  const handleOpenMenu = () => {
    setOpenedMenu(!openedMenu);
  };

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDir(scrollY > lastScrollY ? 'scrolling down' : 'scrolling up');

      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollDir]);

  const handleCookieConsent = () => {
    sessionStorage.setItem('cookieConsent', 'true');
    setShowCookieWarning(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        scrollBehavior: 'smooth',
        overscrollBehaviorY: 'smooth',
        overflowY: openedMenu ? 'hidden' : 'auto',
        height: '100%'
      }}
    >
      <Condition>
        <Condition.If condition={loading}>
          <LinearProgress
            color="inherit"
            sx={{
              zIndex: 9999,
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              color: 'primary.light'
            }}
          />
        </Condition.If>
      </Condition>
      <Menu
        scrollDir={scrollDir}
        openedMenu={openedMenu}
        handleOpenMenu={handleOpenMenu}
      />
      <Box>
        {children}
        <Box width="100%" overflow="hidden">
          <Footer />
        </Box>
      </Box>
      <If condition={showCookieWarning}>
        <CookieWarningBanner onPress={handleCookieConsent} />
      </If>
    </Box>
  );
};

export default WebsiteTemplate;
