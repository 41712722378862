import { AxiosError } from 'axios';
import { IChangePasswordFormValues } from 'components/molecules/ChangePasswordForm/ChangePasswordForm';
import { IResetPasswordForm } from 'components/molecules/ResetPasswordForm/ResetPasswordForm';
import { ISignUpForm } from 'components/molecules/SignUpForm/SignUpForm';
import { OrganizationUserDTO, UserDTO } from 'models/UserDTO';
import APIService, {
  APIServiceDefaultParams,
  BaaSServerResponse
} from 'services/api/APIService';

export interface ISignUpPOSTData {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
  organization: {
    name: string;
    document: string;
  };
}

export interface IValidateCodePOSTData {
  email: string;
  code: string;
}

export interface ISendCodePOSTData {
  email: string;
}

export interface IRecoveryPasswordPATCHData {
  email: string;
  code: string;
  new_password: string;
  password_confirmation: string;
}

export interface ISignInPOSTData {
  email: string;
  password: string;
  twoFACode?: string;
}

export interface SignInResponse {
  success: boolean;
  data: {
    message_type: string;
    token: string;
    message: string;
  };
}

export const signInBaaS = async (
  data: ISignInPOSTData,
  reCaptchaToken: string
): Promise<SignInResponse> => {
  return APIService.getInstance({ api: 'dashboard' })
    .post<ISignInPOSTData, SignInResponse>('/dashboard/auth/signin', data, {
      headers: {
        'x-recaptcha-token': reCaptchaToken
      }
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      return error.response?.data;
    });
};

export const getTokenData = async (
  token: string
): Promise<BaaSServerResponse<UserDTO>> => {
  return APIService.getInstance({ api: 'dashboard' })
    .get<UserDTO>('/dashboard/auth/user/me', {
      headers: {
        'x-auth-token': token
      }
    })
    .then((res) => res.data)
    .catch((error: AxiosError<BaaSServerResponse>) => {
      const { data } = error?.response ?? {};
      return data;
    });
};

export const signUp = async (
  values: ISignUpForm,
  reCaptchaToken: string
): Promise<BaaSServerResponse<{ id: string; message: string }>> => {
  return APIService.getInstance({ api: 'dashboard' })
    .post<ISignUpPOSTData>(
      '/dashboard/auth/signup',
      {
        name: values.name,
        email: values.email,
        password: values.password,
        password_confirmation: values.confirmPassword,
        organization: {
          name: values.companyName,
          document: values.documentNumber.replace(/[^\d]/g, '')
        }
      },
      {
        headers: {
          'x-recaptcha-token': reCaptchaToken
        }
      }
    )
    .then((res) => res.data)
    .catch((error: AxiosError<BaaSServerResponse>) => {
      return error.response.data.data;
    });
};

export const forgotPasswordSendEmail = async (
  email: string,
  reCaptchaToken?: string
): Promise<BaaSServerResponse> => {
  return APIService.getInstance({ api: 'dashboard' })
    .post(
      '/dashboard/auth/recovery-password',
      {
        email
      },
      {
        headers: {
          'x-recaptcha-token': reCaptchaToken
        }
      }
    )
    .then((res) => res.data)
    .catch((error: AxiosError<BaaSServerResponse>) => {
      return error.response.data.data;
    });
};

export const forgotPasswordValidateCode = async (
  email: string,
  validationCode: string
): Promise<BaaSServerResponse> => {
  return APIService.getInstance({ api: 'dashboard' })
    .post('/dashboard/auth/recovery-password/confirm', {
      email,
      code: validationCode
    })
    .then((res) => res.data)
    .catch((error: AxiosError<BaaSServerResponse>) => {
      return error.response.data.data;
    });
};

export const verifyRegisterValidationCode = async (
  email: string,
  validationCode: string
): Promise<BaaSServerResponse> => {
  return APIService.getInstance({ api: 'dashboard' })
    .patch('/dashboard/auth/confirm-register', {
      email,
      code: validationCode
    })
    .then((res) => res.data)
    .catch((error: AxiosError<BaaSServerResponse>) => {
      return error.response?.data;
    });
};

export const recoveryPassword = async (
  validationCode: string,
  email: string,
  values: IResetPasswordForm
): Promise<BaaSServerResponse> => {
  return APIService.getInstance({ api: 'dashboard' })
    .patch<IRecoveryPasswordPATCHData>('/dashboard/auth/recovery-password', {
      email,
      code: validationCode,
      new_password: values.password,
      password_confirmation: values.confirmPassword
    })
    .then((res) => res.data)
    .catch((error: AxiosError<BaaSServerResponse>) => {
      return error.response?.data;
    });
};

export const changePassword = async (
  data: IChangePasswordFormValues
): Promise<BaaSServerResponse> => {
  return APIService.getInstance({ api: 'dashboard' })
    .patch('/dashboard/auth/user/update-password', data)
    .then((res) => res.data)
    .catch((error: AxiosError<BaaSServerResponse>) => {
      return error.response?.data;
    });
};

export const resendConfirmationCode = async (
  email: string
): Promise<BaaSServerResponse> => {
  return APIService.getInstance({ api: 'dashboard' })
    .patch('/dashboard/auth/signup/resend-confirmation-code', { email })
    .then((res) => res.data)
    .catch((error: AxiosError<BaaSServerResponse>) => {
      return error.response?.data;
    });
};

export const getOrganizationUsers = async ({
  accessToken
}: APIServiceDefaultParams): Promise<
  BaaSServerResponse<OrganizationUserDTO[]>
> => {
  return APIService.getInstance({ api: 'dashboard', accessToken })
    .get('/dashboard/auth/users')
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      return error.response?.data;
    });
};
