import {
  CheckIcon,
  CloseIcon,
  CookiesDuotoneIcon
} from '@bws-bitfy/icons-react';
import { Box, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import { memo } from 'react';

interface CookieWarningBannerProps {
  onPress?: () => void;
}

function CookieWarningBanner({
  onPress
}: CookieWarningBannerProps): JSX.Element {
  const theme = useTheme();
  return (
    <Box
      width="100%"
      bgcolor={theme.palette.background.elevated}
      position="fixed"
      bottom={0}
      justifyContent="center"
      alignItems="center"
      zIndex={100}
      sx={{
        padding: 2
      }}
      flexDirection={{
        xs: 'column',
        md: 'row'
      }}
      display="flex"
    >
      <Box
        maxWidth="1056px"
        width="100%"
        display="flex"
        justifyContent={{
          xs: 'center',
          md: 'space-between'
        }}
        flexDirection={{
          xs: 'column',
          md: 'row'
        }}
      >
        <Box
          justifyContent="center"
          alignItems="center"
          display="flex"
          maxWidth="1056px"
          flexDirection={{
            xs: 'column',
            md: 'row'
          }}
          sx={{
            marginBottom: {
              sm: 2,
              md: 0
            }
          }}
        >
          <CookiesDuotoneIcon
            width={40}
            height={40}
            fill={theme.palette.text.secondary}
          />
          <Text
            sx={{
              marginTop: {
                sm: 2,
                md: 0
              }
            }}
            color="white"
            marginLeft={3}
            intlPath="webSiteEn.Cookie.warning_text"
          />
        </Box>
        <Box
          alignItems="center"
          display="flex"
          justifyContent={{
            xs: 'center',
            md: 'space-between'
          }}
          flexDirection={{
            xs: 'column',
            md: 'row'
          }}
        >
          <Button
            variant="overlayed"
            color="primary"
            onClick={onPress}
            intlpath="webSiteEn.Cookie.accept"
            startIcon={<CheckIcon />}
            sx={{
              marginRight: {
                sm: 0,
                md: 4
              },
              marginBottom: {
                sm: 2,
                md: 0
              }
            }}
          />
          <IconButton onClick={onPress}>
            <CloseIcon fill={theme.palette.text.secondary} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

export default memo(CookieWarningBanner);
