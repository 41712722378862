import { AxiosError } from 'axios';
import { InviteMemberDTO, MembersListDTO } from 'models/UserDTO';
import APIService, {
  APIServiceDefaultParams,
  BaaSServerResponse,
  PagingResponse
} from 'services/api/APIService';
import {
  InviteMemberBodyData,
  SignupInviteMemberBodyData
} from './api/schemas/InviteMemberSchema';

export const getMembersList = async (
  defaultParams?: APIServiceDefaultParams
): Promise<BaaSServerResponse<PagingResponse<MembersListDTO>>> => {
  const { accessToken, page, perPage } = defaultParams || {};
  return APIService.getInstance({
    api: 'dashboard',
    accessToken
  })
    .get<BaaSServerResponse<PagingResponse<MembersListDTO>>>(
      '/dashboard/auth/invites',
      {
        params: {
          page: page || 1,
          per_page: perPage || 10
        }
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((error: AxiosError) => {
      if (accessToken) {
        throw error;
      }
      const { data } = error?.response ?? {};
      return data;
    });
};

export const inviteMember = async (
  email: string
): Promise<BaaSServerResponse<InviteMemberDTO>> => {
  return APIService.getInstance({ api: 'dashboard' })
    .post<InviteMemberBodyData>(`/dashboard/auth/invites`, {
      email
    })
    .then((res) => {
      return res.data;
    })
    .catch((error: AxiosError<BaaSServerResponse>) => {
      return error?.response?.data;
    });
};

export const signupInviteMember = async ({
  body
}: APIServiceDefaultParams<SignupInviteMemberBodyData>): Promise<BaaSServerResponse> => {
  return APIService.getInstance({ api: 'dashboard' })
    .patch<SignupInviteMemberBodyData>(`/dashboard/auth/invites`, body)
    .then((res) => {
      return res.data;
    })
    .catch((error: AxiosError) => {
      return error?.response?.data;
    });
};

export const deleteMember = async (id: string): Promise<void> => {
  return APIService.getInstance({ api: 'dashboard' })
    .delete(`/dashboard/auth/invites/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((error: AxiosError) => {
      return error.response?.data;
    });
};
export const validateInviteCode = async (
  params: APIServiceDefaultParams & {
    code: string;
  }
): Promise<BaaSServerResponse> => {
  const { code, accessToken } = params;
  return APIService.getInstance({ api: 'dashboard', accessToken })
    .get(`/dashboard/auth/invites/check/${code}`)
    .then((res) => {
      return res.data;
    })
    .catch((error: AxiosError) => {
      return error.response?.data;
    });
};
